import transformColorPropsToSBS from '../StylishBox/helpers/transformColorPropsToSBS';
import { StylishBoxColorProps, StylishBoxCSSProps, StylishBoxStyles } from '../StylishBox/types';
import { Theme } from '../theme/types';
import { getTextEllipsisStyles, variantElementMap, variantFontStylesMap } from './constants';
import { TextAlignProps, TypographyAllowedElements, TypographyVariantResponsive, Variant } from './types';

function getFontStyles(
  variant: Variant,
  fontFamily: Theme['typography']['fontFamily'],
  fontFamilyAccented: Theme['typography']['fontFamilyAccented'],
): StylishBoxStyles {
  const { isAccented, ...restStyles } = variantFontStylesMap[variant];

  return {
    ...restStyles,
    fontFamily: isAccented ? fontFamilyAccented : fontFamily,
  };
}

export function getResponsiveFontStyles(
  variant: TypographyVariantResponsive<Variant>,
  fontFamily: Theme['typography']['fontFamily'],
  fontFamilyAccented: Theme['typography']['fontFamilyAccented'],
): StylishBoxCSSProps {

  if (typeof variant !== 'object') {
    return getFontStyles(variant, fontFamily, fontFamilyAccented);
  }

  const generalStyles = getFontStyles(variant.general, fontFamily, fontFamilyAccented);

  const result = Object.keys(generalStyles).reduce((acc, styleName) => {
    acc[styleName] = {
      general: generalStyles[styleName],
    };

    return acc;
  }, {});

  if (variant.desktop) {
    const desktopStyles = getFontStyles(variant.desktop, fontFamily, fontFamilyAccented);

    Object.keys(desktopStyles).reduce((acc, styleName) => {
      acc[styleName].desktop = desktopStyles[styleName];

      return acc;
    }, result);
  }

  if (variant.desktopTablet) {
    const desktopTabletStyles = getFontStyles(variant.desktopTablet, fontFamily, fontFamilyAccented);

    Object.keys(desktopTabletStyles).reduce((acc, styleName) => {
      acc[styleName].desktopTablet = desktopTabletStyles[styleName];

      return acc;
    }, result);
  }

  if (variant.tablet) {
    const tabletStyles = getFontStyles(variant.tablet, fontFamily, fontFamilyAccented);

    Object.keys(tabletStyles).reduce((acc, styleName) => {
      acc[styleName].tablet = tabletStyles[styleName];

      return acc;
    }, result);
  }

  if (variant.tabletPhone) {
    const tabletPhoneStyles = getFontStyles(variant.tabletPhone, fontFamily, fontFamilyAccented);

    Object.keys(tabletPhoneStyles).reduce((acc, styleName) => {
      acc[styleName].tabletPhone = tabletPhoneStyles[styleName];

      return acc;
    }, result);
  }

  if (variant.phone) {
    const phoneStyles = getFontStyles(variant.phone, fontFamily, fontFamilyAccented);

    Object.keys(phoneStyles).reduce((acc, styleName) => {
      acc[styleName].phone = phoneStyles[styleName];

      return acc;
    }, result);
  }

  return result;
}

export const getElement = (
  elementProps: TypographyAllowedElements | undefined,
  variant: TypographyVariantResponsive<Variant>,
): TypographyAllowedElements => {
  if (elementProps) {
    return elementProps;
  }

  const variantGeneral = typeof variant === 'string'
    ? variant
    : variant.general;

  return variantElementMap[variantGeneral];
};

export interface GetSBSParams {
  variant: TypographyVariantResponsive<Variant>;
  ellipsisTextRows?: number;
  colorProps: StylishBoxColorProps | undefined;
  colorDefault: Theme['typography']['color'];
  themeColors: Theme['base']['color'];
  textAlign?: TextAlignProps;
  sbsProps?: StylishBoxStyles;
  fontFamily: Theme['typography']['fontFamily'];
  fontFamilyAccented: Theme['typography']['fontFamilyAccented'];
}
export const getSBS = ({
  variant,
  ellipsisTextRows,
  colorProps,
  themeColors,
  colorDefault,
  textAlign,
  sbsProps,
  fontFamily,
  fontFamilyAccented,
}: GetSBSParams): StylishBoxStyles => {

  const fontStyles = getResponsiveFontStyles(variant, fontFamily, fontFamilyAccented);
  const ellipsisStyles: StylishBoxStyles = !!ellipsisTextRows ? getTextEllipsisStyles(ellipsisTextRows) : {};
  const color = transformColorPropsToSBS(colorProps, themeColors, colorDefault);

  return {
    color,
    textAlign,
    ...fontStyles,
    ...ellipsisStyles,
    ...sbsProps,
  };
};
