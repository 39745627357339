import React, { JSX, ReactNode } from 'react';
import { StylishBoxStyles } from './types';
import styled from '@emotion/styled';
import { getResultEmotionStyles } from './helpers/getResultEmotionStyles';
import isPropValid from '@emotion/is-prop-valid';

export type StylishBoxAllowedTagName = React.HTMLElementType;

type DataAttribute = {
  [dataAttribute: `data-${string}`]: any;
  /** Please use dataMarker of StylishBoxProps*/
  'data-marker'?: undefined;
  /** Please use dataTestId of StylishBoxProps*/
  'data-testid'?: undefined;
};

export type StylishBoxElementProps<T extends StylishBoxAllowedTagName> =
  Omit<JSX.IntrinsicElements[T], 'children' | 'className'>
  & DataAttribute;

export type StylishBoxProps<T extends StylishBoxAllowedTagName> = {
  children?: ReactNode;
  element?: T;
  sbs?: StylishBoxStyles;
  elementProps?: StylishBoxElementProps<T>;
  className?: string;
  dataMarker?: string;
  dataTestId?: string;
};

type StyledStylishBoxProps =
  Omit<StylishBoxProps<StylishBoxAllowedTagName>, 'elementProps'>
  & object

const StyledComponent = styled('div', {
  shouldForwardProp: propName => isPropValid(propName) && propName !== 'sbs',
})(({ sbs }: StyledStylishBoxProps) => {
  return getResultEmotionStyles(sbs);
});

const StylishBox = <T extends StylishBoxAllowedTagName>({
  children,
  sbs,
  element = 'div' as T,
  elementProps,
  dataMarker,
  dataTestId,
  className,
}: StylishBoxProps<T>) => {

  return (
    <StyledComponent
      as={element}
      {...elementProps as object}
      className={className}
      data-marker={dataMarker}
      data-testid={dataTestId}
      sbs={sbs}
    >
      {children}
    </StyledComponent>
  );
};

export default StylishBox;
