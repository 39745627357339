import { StylishBoxStyles } from '../StylishBox/types';
import { ValidateVariantElementMap, VariantFontStylesMap } from './types';

export const variantElementMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  text1: 'p',
  text2: 'p',
  text3: 'p',
  text4: 'p',
  text5: 'p',
  text6: 'p',
  text7: 'p',
  caption1: 'p',
  caption2: 'p',
} as const;

/* Этот хак дает возможность проверить ограниченные значения свойств variantElementMap */
(():ValidateVariantElementMap<typeof variantElementMap> => variantElementMap)();

export const variantFontStylesMap: VariantFontStylesMap = {
  h1: {
    fontSize: '48px',
    lineHeight: '62px',
    fontWeight: '400',
    textTransform: 'none',
    isAccented: true,
    letterSpacing: 'normal',
  },
  h2: {
    fontSize: '40px',
    lineHeight: '54px',
    fontWeight: '400',
    textTransform: 'none',
    isAccented: true,
    letterSpacing: 'normal',
  },
  h3: {
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: '400',
    textTransform: 'none',
    isAccented: true,
    letterSpacing: 'normal',
  },
  h4: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: '600',
    textTransform: 'none',
    isAccented: false,
    letterSpacing: 'normal',
  },
  h5: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '600',
    textTransform: 'none',
    isAccented: false,
    letterSpacing: 'normal',
  },

  text1: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '600',
    textTransform: 'none',
    isAccented: false,
    letterSpacing: 'normal',
  },
  text2: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    textTransform: 'none',
    isAccented: false,
    letterSpacing: 'normal',
  },
  text3: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '600',
    textTransform: 'none',
    isAccented: false,
    letterSpacing: 'normal',
  },
  text4: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
    textTransform: 'none',
    isAccented: false,
    letterSpacing: 'normal',
  },
  text5: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '600',
    textTransform: 'none',
    isAccented: false,
    letterSpacing: 'normal',
  },
  text6: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '400',
    textTransform: 'none',
    isAccented: false,
    letterSpacing: 'normal',
  },
  text7: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '600',
    textTransform: 'uppercase',
    isAccented: false,
    letterSpacing: '1px',
  },
  caption1: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    textTransform: 'none',
    isAccented: false,
    letterSpacing: 'normal',
  },
  caption2: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '600',
    textTransform: 'uppercase',
    isAccented: false,
    letterSpacing: '1px',
  },
};

export const getTextEllipsisStyles = (rows: number): StylishBoxStyles =>  ({
  display: '-webkit-box',
  boxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineClamp: String(rows),
  wordBreak: 'break-word',
});
